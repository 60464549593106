import * as Sentry from "@sentry/nextjs";
import env from "./env"

const {SENTRY_DSN} = env;

const initialTelemetry = (isClient: boolean) =>
  SENTRY_DSN
    ? Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
      })
    : console.log(
        "no SENTRY_DSN env!",
        "cannot throw",
        isClient ? "client" : "server",
        "error"
      );
export default initialTelemetry;
