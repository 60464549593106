// Providers
import { ReactNode, useEffect } from "react";
import { MantineProvider } from "@mantine/core";

// Search
import { SpotlightProvider } from "@mantine/spotlight";
import { IconSearch } from "@tabler/icons";

// Navigation progress
import {
  NavigationProgress,
  completeNavigationProgress,
  startNavigationProgress,
} from "@mantine/nprogress";
import { useRouter } from "next/router";

type Slot = { children: ReactNode };
export default function Provider({ children }: Slot) {
  const router = useRouter();
  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && startNavigationProgress();
    const handleComplete = () => completeNavigationProgress();
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);
    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.asPath, router.events]);
  return (
    <MantineProvider
      theme={{ colorScheme: "dark", focusRing: "never" }}
      withGlobalStyles
      withNormalizeCSS
    >
      <NavigationProgress />
      <SpotlightProvider
        shortcut={["mod + K", "`", "/"]}
        searchIcon={<IconSearch size={18} />}
        searchPlaceholder="Search..."
        nothingFoundMessage="Pure void..."
        actions={[]}
      >
        {children}
      </SpotlightProvider>
    </MantineProvider>
  );
}
